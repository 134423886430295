import { useEffect, useState } from 'react'
import { range, sample } from 'lodash'
import { animated, easings, useInView, useSpring } from '@react-spring/web'

interface OceanProps {
  className?: string
  topColor?: string
  middleColor?: string
  bottomColor?: string
  animationDuration?: number
}

const TOP_PATHS = [
  'M0 506L21.5 511.8C43 517.7 86 529.3 128.8 528C171.7 526.7 214.3 512.3 257.2 514.2C300 516 343 534 385.8 541.5C428.7 549 471.3 546 514.2 547.3C557 548.7 600 554.3 642.8 547.8C685.7 541.3 728.3 522.7 771.2 514.5C814 506.3 857 508.7 878.5 509.8L900 511L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z',
  'M0 530L21.5 533.2C43 536.3 86 542.7 128.8 542.7C171.7 542.7 214.3 536.3 257.2 530.2C300 524 343 518 385.8 514.5C428.7 511 471.3 510 514.2 507.8C557 505.7 600 502.3 642.8 503.8C685.7 505.3 728.3 511.7 771.2 516.3C814 521 857 524 878.5 525.5L900 527L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z',
  'M0 530L21.5 534.8C43 539.7 86 549.3 128.8 553.2C171.7 557 214.3 555 257.2 544.8C300 534.7 343 516.3 385.8 517.7C428.7 519 471.3 540 514.2 548.5C557 557 600 553 642.8 545.7C685.7 538.3 728.3 527.7 771.2 519.7C814 511.7 857 506.3 878.5 503.7L900 501L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z',
  'M0 542L21.5 541.5C43 541 86 540 128.8 536.2C171.7 532.3 214.3 525.7 257.2 527.3C300 529 343 539 385.8 538.5C428.7 538 471.3 527 514.2 528.8C557 530.7 600 545.3 642.8 545.2C685.7 545 728.3 530 771.2 520.8C814 511.7 857 508.3 878.5 506.7L900 505L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z',
  'M0 523L21.5 525.3C43 527.7 86 532.3 128.8 527.7C171.7 523 214.3 509 257.2 510.8C300 512.7 343 530.3 385.8 533.7C428.7 537 471.3 526 514.2 522.2C557 518.3 600 521.7 642.8 525C685.7 528.3 728.3 531.7 771.2 536.5C814 541.3 857 547.7 878.5 550.8L900 554L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z'
]

const MIDDLE_PATHS = [
  'M0 548L21.5 544C43 540 86 532 128.8 534.7C171.7 537.3 214.3 550.7 257.2 550.3C300 550 343 536 385.8 531.5C428.7 527 471.3 532 514.2 532.5C557 533 600 529 642.8 529C685.7 529 728.3 533 771.2 537.5C814 542 857 547 878.5 549.5L900 552L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z',
  'M0 539L21.5 536.3C43 533.7 86 528.3 128.8 532.7C171.7 537 214.3 551 257.2 558.2C300 565.3 343 565.7 385.8 559.3C428.7 553 471.3 540 514.2 539.2C557 538.3 600 549.7 642.8 553.8C685.7 558 728.3 555 771.2 550.3C814 545.7 857 539.3 878.5 536.2L900 533L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z',
  'M0 575L21.5 575C43 575 86 575 128.8 568.8C171.7 562.7 214.3 550.3 257.2 548C300 545.7 343 553.3 385.8 556.7C428.7 560 471.3 559 514.2 558C557 557 600 556 642.8 552.3C685.7 548.7 728.3 542.3 771.2 538.5C814 534.7 857 533.3 878.5 532.7L900 532L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z',
  'M0 534L21.5 539C43 544 86 554 128.8 557.8C171.7 561.7 214.3 559.3 257.2 552.3C300 545.3 343 533.7 385.8 535.2C428.7 536.7 471.3 551.3 514.2 559.7C557 568 600 570 642.8 568.3C685.7 566.7 728.3 561.3 771.2 557C814 552.7 857 549.3 878.5 547.7L900 546L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z',
  'M0 542L21.5 539.8C43 537.7 86 533.3 128.8 538.7C171.7 544 214.3 559 257.2 559.7C300 560.3 343 546.7 385.8 542.2C428.7 537.7 471.3 542.3 514.2 547.2C557 552 600 557 642.8 554.5C685.7 552 728.3 542 771.2 543C814 544 857 556 878.5 562L900 568L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z'
]

const BOTTOM_PATHS = [
  'M0 565L21.5 562.7C43 560.3 86 555.7 128.8 553C171.7 550.3 214.3 549.7 257.2 551.3C300 553 343 557 385.8 558.3C428.7 559.7 471.3 558.3 514.2 556.8C557 555.3 600 553.7 642.8 555.8C685.7 558 728.3 564 771.2 566C814 568 857 566 878.5 565L900 564L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z',
  'M0 561L21.5 564.5C43 568 86 575 128.8 577.7C171.7 580.3 214.3 578.7 257.2 578C300 577.3 343 577.7 385.8 575.7C428.7 573.7 471.3 569.3 514.2 565.7C557 562 600 559 642.8 556.2C685.7 553.3 728.3 550.7 771.2 554.3C814 558 857 568 878.5 573L900 578L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z',
  'M0 576L21.5 574C43 572 86 568 128.8 564.5C171.7 561 214.3 558 257.2 558.8C300 559.7 343 564.3 385.8 563.7C428.7 563 471.3 557 514.2 557C557 557 600 563 642.8 565.7C685.7 568.3 728.3 567.7 771.2 567.3C814 567 857 567 878.5 567L900 567L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z',
  'M0 588L21.5 582.8C43 577.7 86 567.3 128.8 565C171.7 562.7 214.3 568.3 257.2 571.2C300 574 343 574 385.8 571.2C428.7 568.3 471.3 562.7 514.2 560.8C557 559 600 561 642.8 561.7C685.7 562.3 728.3 561.7 771.2 565.3C814 569 857 577 878.5 581L900 585L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z',
  'M0 577L21.5 577.8C43 578.7 86 580.3 128.8 580C171.7 579.7 214.3 577.3 257.2 577.3C300 577.3 343 579.7 385.8 577C428.7 574.3 471.3 566.7 514.2 562C557 557.3 600 555.7 642.8 558.3C685.7 561 728.3 568 771.2 573.7C814 579.3 857 583.7 878.5 585.8L900 588L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z'
]

export default function Ocean({
  className,
  topColor = '#47d5fc',
  middleColor = '#1cbeeb',
  bottomColor = '#0782a6',
  animationDuration = 2000
}: OceanProps) {
  const [ref, inView] = useInView()
  const [currentPath, setCurrentPath] = useState(0)

  const [{ d: top }, setTop] = useSpring(() => ({
    d: TOP_PATHS[0],
    config: { duration: animationDuration, easing: easings.easeInOutSine }
  }))

  const [{ d: middle }, setMiddle] = useSpring(() => ({
    d: MIDDLE_PATHS[0],
    config: { duration: animationDuration, easing: easings.easeInOutSine }
  }))

  const [{ d: bottom }, setBottom] = useSpring(() => ({
    d: BOTTOM_PATHS[0],
    config: { duration: animationDuration, easing: easings.easeInOutSine }
  }))

  useEffect(() => {
    if (inView) {
      const timeouts: NodeJS.Timeout[] = []

      timeouts.push(
        setTimeout(() => {
          const paths = range(TOP_PATHS.length)
          paths.splice(currentPath, 1)
          const newPath = sample(paths) as number
          setTop({ d: TOP_PATHS[newPath] })

          timeouts.push(
            setTimeout(() => {
              setMiddle({ d: MIDDLE_PATHS[newPath] })

              timeouts.push(
                setTimeout(() => {
                  setBottom({ d: BOTTOM_PATHS[newPath] })
                  setCurrentPath(newPath)
                }, animationDuration / 3)
              )
            }, animationDuration / 3)
          )
        }, animationDuration / 3)
      )

      return () => {
        timeouts.map(clearTimeout)
      }
    }
  }, [inView, currentPath])

  return (
    <svg
      viewBox="0 0 900 100"
      preserveAspectRatio="none"
      className={className}
      ref={ref}
    >
      {inView && (
        <g transform="translate(0, -500)">
          <animated.path d={top} fill={topColor} className="top" />
          <animated.path d={middle} fill={middleColor} className="middle" />
          <animated.path d={bottom} fill={bottomColor} className="bottom" />
        </g>
      )}
    </svg>
  )
}
